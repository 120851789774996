<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  
      <v-dialog persistent v-model="isLoading">
            <v-row justify="center">
            <v-card width="400" >
              <center class="pa-5">
               Importing Book data.<br />
                Please Wait It will Take Time..
                
              </center>
              <div class="ma-6">
              <v-progress-linear 
      v-model="percent"
      color="amber"
      height="25"
      
    >
     <template v-slot:default="{ value }">
        <strong>{{ Math.ceil(value) }}%</strong>
      </template>
      </v-progress-linear>
              </div>
            </v-card>
            </v-row>
          </v-dialog>
    <v-card class="card">
      <v-card-title class="heading justify-start">Select Property Which You Want To Edit.</v-card-title>
      <v-card-text>
      <div v-if="init_loading">
  <v-skeleton-loader
          v-bind="attrs"
          type="three-line,  article"
        ></v-skeleton-loader>

  </div>
  <div v-else>
       <br/>
            
            <v-row>
              <v-col cols="12" sm="3" md="3" v-for="(item,index) in filedata.tablecolumn" :key='index'>
             <b> <v-checkbox :label="item.name" @click="isvisiable" v-model="item.status"></v-checkbox></b>
              </v-col>

            </v-row>
  </div>
      </v-card-text>
    </v-card>
    <div v-if="isselected">
     <div class="pa-5 ma-5 grey lighten-3" v-if="Array.isArray(filedata.assectionnolist) && filedata.assectionnolist.length">
      <v-row justify="center">
      <v-btn   @click="exportexcel()" color="success" dark class="mb-2">
          Sample File Download
          <v-icon dark>mdi-file-excel</v-icon>
        </v-btn>
         </v-row>
       
     
          <v-simple-table class=" grey lighten-3"  id="exceltable" style="display:none;">
            <tbody>
              <tr>
                  <td align="center">Accession No</td>
                  <td v-for="(item,index) in filedata.tablecolumn" v-if="item.status" :key='index' >
                    {{item.name}}
                  </td>
                  <td v-if="vendorvisible">vendor</td>
              </tr>
              <tr v-for="(item,index) in filedata.assectionnolist" :key='index'>
                  <td >{{item.accession_number}}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>

         <v-row class="justify-content-center">
                        <v-col lg="5" sm="12" md="5" class="import-box">
                            <label class="label">IMPORT XLSX</label>
                            <v-file-input
                                dense
                                v-model="file"
                                :rules="[v => !!v || 'File required']"
                                persistent-hint
                                color="rgb(1, 127, 152)"
                                label="Choose File"
                                
                                prepend-icon="mdi-paperclip"
                                :error-messages="!fileStatus?'File Required':''"
                                outlined
                                show-size
                            >
                            </v-file-input>
                            <v-btn  @click="importCsv" class="button">
                                Import
                                <v-icon right>mdi-cloud-upload</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                              <template>
                    <h5 v-if="failList != null" class="mt-4" style="text-align: center; color: red;">Failed Record</h5>
                    <v-simple-table v-if="failList !== null">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                        <th>Excel row no</th>
                                         <th>Error</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in failList" :key="item.name">
                                  
                                  <th> {{item.rowno}} </th>
                                    <th>  {{item.error}}  </th>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </template>
    </div>
  
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import {table2excel} from "../jquery.table2excel";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
  data: () => ({

      filedata:[],
      failList:[],
      file:null,
      isLoading:false,
      snackbar: false,
      vendorvisible:false,
      snackbar_msg: "",
      color:"",
      isselected:false,
      count:-1,
      totalbook:0,
      percent: 0,
      totalbooksize:0,
      init_loading:false,
  }),

  mounted() {
     this.init_loading=true;
    axios
      .post("/Librarian/bulkbookedit")
      .then((res) => {
        this.filedata= res.data.filedata;
         this.init_loading=false;
      })       
  },

  methods: {
    isvisiable(){
        for(var i in  this.filedata.tablecolumn){
          if( this.filedata.tablecolumn[i].status){
              this.isselected=true;
              if(this.filedata.tablecolumn[i].name == 'bill_no')
                  this.vendorvisible = true;
              else
                  this.vendorvisible = false;
             break;
          }else{
            this.isselected = false;
            this.vendorvisible = false
          }
        }
    },
     showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            fileuploading(){
  let formData = new FormData();
                        this.fileStatus = true;
                        formData.append("file", this.file);
                        formData.append("data",JSON.stringify( this.filedata.tablecolumn));
                          formData.append("count", this.count);
                        axios
                            .post("Librarian/editbulkbook", formData, {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                },
                            })
                            .then((res) => {
                             
                             
                                if (res.data.status == "200") {
                                 if(this.count!=-1){
                                        
                                 
                                    
                                    for(var i=0;i<res.data.failList.length;i++){
                                         this.failList.push(res.data.failList[i]);
                                    }
                                  
                                    }
                                  
                                    this.count=res.data.count;
                                    this.totalbooksize=res.data.totalbooksize;
                                    this.percent=(this.count/this.totalbooksize)*100;
                                    if(res.data.isdone)
                                    {this.count=-1;
                                        this.isLoading=false;
                                            this.showSnackbar("#4caf50", "success");
                                            this.file=null;
                                            this.percent=0;
                                    }
                                    else{
                                        this.fileuploading();
                                    }
                                }
                           
                            })
                          
                            .finally(() => {
                                
                              
                            });
            },
             importCsv() {
               this.isLoading=true;
              
                if (this.file != null) {
                   if (!(this.file.name.toLowerCase().includes(".xls") || this.file.name.toLowerCase().includes(".XLS"))) {
                       this.showSnackbar("red", "File must be in xlsx format Only.!");
                   } else{
                        this.fileuploading();
                    }
                } else {
                    this.fileStatus = false;
                    this.showSnackbar("#b71c1c", "Please select file");
                }
                
            },
          exportexcel() {
        
            $("#exceltable").table2excel({
            name: "Worksheet Name",
            filename: "Book_edit", //do not include extension
            fileext: ".xlsx", // file extension
          }); 
      
        },

  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.v-icon-size {
  font-size: 20px;
}
.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}
.edit-avatar:hover {
  background-color: #b0bec5;
}
.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}
.edit-v-icon:hover {
  color: white;
}
.fields {
  padding: 0px !important;
}
.isdonated {
  margin-top: 15px;
  padding-left: 15px !important;
}
.check {
  color: green;
  font-size: 20px;
  padding-right: 10px;
}
.cross {
  color: red;
  font-size: 20px;
  padding-right: 5px;
}

.example {
  margin-top: 0px;
  background: #ffff;
  border: 1px solid rgb(171, 169, 169);
  border-radius: 50px;
  height: 55px;
  width: 100%; 
}

.example:focus {
 
   border-radius: 50px;
   border-color: #2f74b0;
     border-width: 2px;
       
       outline: 0;
}

.required:after {
  content: " *";
  color: red;
}

.date {
  border: 2px solid red;
}

</style>